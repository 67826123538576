import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { GestaoAcessoService } from '@core/services/gestao-acesso.service';

@Component({
  selector: 'app-radio-protecao-expansion',
  templateUrl: './radio-protecao-expansion.component.html',
  styleUrl: './radio-protecao-expansion.component.scss'
})
export class RadioProtecaoExpansionComponent implements AfterViewInit {
  @ViewChild('radioProtecaoExpansionPanel') expansionPanel?: MatExpansionPanel;
  gestaoAcessoService = inject(GestaoAcessoService);
  constructor(private router: Router) {}

  pesquisaProcedimento$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'pesquisaProcedimentos'
  });
  cabineIntegrada$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'levantamentoCabineIntegrada'
  });
  cabineExposicao$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'levantamentoCabineExposicao'
  });
  levantamentoArea$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'levantamentoArea'
  });
  radioProtecao7B$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'levantamento7B'
  });
  radioProtecaoARD$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'levantamentoARD'
  });
  verificacaoProcesso$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'verificacaoProcesso'
  });
  inserirRelatorioRadioprotecao$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'inserirRelatorioRadioprotecao'
  });
  consultaRelatorios$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'consultaRelatoriosRadioprotecao'
  });

  consultaMedidores$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'consultaMedidores'
  });

  consultarLimiteDerivado$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'consultarLimiteDerivado'
  });

  rolarParaInicio() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  abrir() {
    const ativo = this.router.isActive('/radioProtecao', {
      fragment: 'ignored',
      matrixParams: 'ignored',
      paths: 'subset',
      queryParams: 'ignored'
    });
    if (ativo) {
      this.expansionPanel?.open();
    }
  }

  ngAfterViewInit(): void {
    this.abrir();
  }
}
