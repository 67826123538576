export const environment = {
  environment: 'main',
  production: false,
  baseURL: 'https://portalapi.ebcotech.xyz/api/v1/',
  rotasSemAutenticacao: [
    'https://portalapi.ebcotech.xyz/api/v1/Autenticacao/AutenticarUsuario'
  ],
  dominiosComAutenticacao: ['https://portalapi.ebcotech.xyz']

  // environment: 'main',
  // production: false,
  // baseURL: 'https://localhost:4444/api/v1/',
  // rotasSemAutenticacao: [
  //   'https://localhost:4444/api/v1/Autenticacao/AutenticarUsuario'
  // ],
  // dominiosComAutenticacao: ['https://localhost:4444']

  // environment: 'main',
  // production: false,
  // baseURL: 'https://localhost:44313/api/v1/',
  // rotasSemAutenticacao: [
  //   'https://localhost:44313/api/v1/Autenticacao/AutenticarUsuario'
  // ],
  // dominiosComAutenticacao: ['https://localhost:44313']

  // environment: 'main',
  // production: false,
  // baseURL: 'http://10.11.12.109:8080/api/v1/',
  // rotasSemAutenticacao: [
  //   'http://10.11.12.109:8080/api/v1/Autenticacao/AutenticarUsuario'
  // ],
  // dominiosComAutenticacao: ['http://10.11.12.109:8080']
};
